import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Form } from 'reactstrap'
import { DEFAULT_TEMPLATE_TAGS, WYSIWYG_SETTINGS_FORMATS } from '../../config/constants';
import { Form as ReduxForm } from 'react-redux-form'
import { lang, withLang } from '../../utils/withLang'
import FormInput from '../../components/FormInput'
import validators from '../../utils/validators'
import Tabs from '../../components/Tabs'

const mapStateToProps = state => ({
  messagesType: state.forms.messagesType,
  isSendByWhatsapp: state.forms.messagesType.is_send_by_whatsapp,
})

const ContentForMen = () => {
  return (<div className="row justify-content-center mr-2">
    <div className="col-12">
      <FormInput
        formats={WYSIWYG_SETTINGS_FORMATS}
        type="wysiwyg"
        templateTags={DEFAULT_TEMPLATE_TAGS}
        model=".text_men"
        label={lang.content_for_men}
        validators={{
          required: validators.required,
        }}
        errorMessages={{
          required: lang.errors.required_field,
        }}
      />
    </div>
  </div>)
}

const ContentForWomen = () => {
  return (<div className="row justify-content-center mr-2">
    <div className="col-12">
      <FormInput
        formats={WYSIWYG_SETTINGS_FORMATS}
        type="wysiwyg"
        templateTags={DEFAULT_TEMPLATE_TAGS}
        model=".text_women"
        label={lang.content_for_women}
        validators={{
          required: validators.required,
        }}
        errorMessages={{
          required: lang.errors.required_field,
        }}
      />
    </div>
  </div>)
}


const SendingSetting = ({isSendByWhatsapp}) => {
  return (
      <div className="card-body pt-1">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <FormInput
              type="text"
              model=".name"
              label={lang.name}
              validators={{
                required: validators.required,
              }}
              errorMessages={{
                required: lang.errors.required_field,
              }}
          />
        </div>
        <div className="col-md-6">
          <FormInput
              type="checkbox"
              id="is_campaign_followup"
              model=".is_campaign_followup"
              label={lang.campaign_followup_template}
          />
          <FormInput
              type="checkbox"
              id="is_promotional_message"
              model=".is_promotional_message"
              label={lang.is_promotional_message}
          />
          <small style={{fontSize: '12px', color: '#666'}}>
            במידה וההודעה היא שיווקית, ייתווסף אליה טקסט המאפשר למשתמש להסיר את עצמו.
            רק הודעות שיווקיות לא יישלחו למשתמשים שהוסרו.
          </small>
        </div>
      </div>
  <hr/>
  <div className="row justify-content-center">
    <div className="col-md-6">
      <FormInput
          type="checkbox"
          id="is_send_by_whatsapp"
          model=".is_send_by_whatsapp"
          icon={"whatsapp"}
          label={lang.is_send_by_whatsapp}
      />
      <FormInput
          type="checkbox"
          id="is_try_send_fallback_sms"
          model=".is_try_send_fallback_sms"
          label={lang.is_try_send_fallback_sms}
      />
      <small style={{fontSize: '12px', color: '#666'}}>
        במידה וההודעה לא נשלחת, תישלח הודעת SMS כדי לוודא שהמשתמש ייקבל את ההודעה.
      </small>
    </div>
    <div className="col-md-6">
      <FormInput
          type="text"
          model=".twilio_content_sid_male"
          label={lang.twilio_content_sid_male}
          validators={{
            required: value => !isSendByWhatsapp || validators.required(value),
          }}
          errorMessages={{
            required: lang.errors.required_field,
          }}
      />
      <FormInput
          type="text"
          model=".twilio_content_sid_female"
          label={lang.twilio_content_sid_female}
          validators={{
            required: value => !isSendByWhatsapp || validators.required(value),
          }}
          errorMessages={{
            required: lang.errors.required_field,
          }}
      />
    </div>
  </div>
  </div>
)
}

const tabs = [
  {
    title: lang.sending_settings,
    label: 'sending_settings',
    component: SendingSetting,
  },
  {
    title: lang.content_for_men,
    label: 'content_for_men',
    component: ContentForMen,
  },
  {
    title: lang.content_for_women,
    label: 'content_for_women',
    component: ContentForWomen,
  },
]

const MessagesTypesForm = ({isSendByWhatsapp}) => {

  const [activeTab, setActiveTab] = useState(tabs[0].label)

  return (
      <Form
          tag={ReduxForm}
          model="forms.messagesType">
        <div className="card-body">
          <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onTabClick={tab => setActiveTab(tab)}
          />

      </div>
    </Form>
  )

}

export default withLang(connect(mapStateToProps)(MessagesTypesForm))