import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import { actions } from 'react-redux-form';
import 'quill-emoji/dist/quill-emoji.css';

import { lang } from '../utils/withLang';
import { getLengthOfSms } from '../modules/messages-types/functions';

class ReactQuillWrapper extends Component {

	//**MAX LENGTH Verification(using state.isMaxLength, state.text, checkCharacterCount, part of onChangeHandler) -
	// made without any logic - there is a bug in React-Quill. Solution from GitHub**/
	//UPDATE: Customer canceled this verification

	state = {
		isMaxLength: false,
		text: '',
	};

	static toolbarNum = 1;

	insertCustomTags = function (args) {
		const cursorPosition = this.quill.getSelection().index;
		this.quill.insertText(cursorPosition, args);
		this.quill.setSelection(cursorPosition + args.length);
	};

	// checkCharacterCount = (event) => {
	//   if (this.state.isMaxLength) {
	//     event.preventDefault()
	//   }
	// }

	modules = {
		'toolbar': {
			'container': `#toolbar${ReactQuillWrapper.toolbarNum}`,
			'handlers': {
				'putUserName': this.putUserName,
				'insertCustomTags': this.insertCustomTags,
				'emoji': () => {
				},
			},
		},
		'clipboard': {
			'matchVisual': false,
		},
		'emoji-toolbar': true,
		'emoji-textarea': true,
		'emoji-shortname': true,
	};

	onChangeHandler = (content, delta, source, editor) => {
		// if (validators.smsLength(content)) {
		const plainText = editor.getText(); // שמירת טקסט נקי בלי HTML
		this.props.dispatch(actions.change(this.props.name, plainText));
		// } else {
		//   toast.error(lang.errors.sms_length)
		//   this.setState({ text: content })
		// }
	};

	generateStylesDynamically () {
		// Obtain data to generate your styles here
		// and format it into a string.
		let output = '';
		const templateTags = this.props.templateTags;

		Object.keys(templateTags).forEach(key => {
			output += `
                    .ql-picker.ql-insertCustomTags [data-value="${templateTags[key]}"]::before {
                      content: '${key}';
                    }
                `;
		});

		return output;
	}

	showSmsLength = (
		activeTab, menContent, womenContent, textFromManualSend, textFromManualRestaurantSend) => {
		if (activeTab === 'content_for_men') {
			return getLengthOfSms(menContent);
		} else if (activeTab === 'content_for_women') {
			return getLengthOfSms(womenContent);
		} else {
			return getLengthOfSms(textFromManualSend);
		}
	};

	render () {

		const { activeTab, menContent, womenContent, textFromManualSend, textFromManualRestaurantSend, templateTags } = this.props;

		const length = this.showSmsLength(activeTab, menContent, womenContent,
			textFromManualSend, textFromManualRestaurantSend);
		const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;
		Quill.register({
			'formats/emoji': EmojiBlot,
			'modules/emoji-shortname': ShortNameEmoji,
			'modules/emoji-toolbar': ToolbarEmoji,
			'modules/emoji-textarea': TextAreaEmoji,
		}, true);

		if (!this.props.formats) {
			return (
				<ReactQuill
					{...this.props}
					value={this.props.value.replace(/\n/g, "<br>")} // מציג '\n' נכון
					onChange={(value, delta, source, editor) => {
						const plainText = editor.getText();
						this.props.dispatch(actions.change(this.props.name, plainText));
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<style
					dangerouslySetInnerHTML={{ __html: this.generateStylesDynamically() }}/>
				<CustomToolbar templateTags={templateTags}/>
				<div>
					<ReactQuill
						{...this.props}
						value={this.props.value.replace(/\n/g, "<br>")} // מציג '\n' נכון
						modules={this.modules}
						formats={this.props.formats}
						theme={'snow'}
						onChange={this.onChangeHandler}
					/>
				</div>
				<div className='row mt-2 justify-content-between px-3'>
          <span><span
			  className='wysiwyg_number'>{length}</span> {lang.sms_length}</span>
					<span>{lang.estimated_sms_quantity} <span
						className='wysiwyg_number'>{Math.ceil(
						length / 160)}</span> </span>

				</div>
			</React.Fragment>
		);
	}
}

const CustomToolbar = (props) => {
	return (
		<div id={`toolbar${ReactQuillWrapper.toolbarNum++}`}>
			<select className="ql-header" defaultValue={'0'}
					onChange={e => e.persist()}>
				<option value="1"/>
				<option value="2"/>
				<option value="3"/>
				<option value="0"/>
			</select>
			<button className="ql-bold"/>
			<button className="ql-italic"/>
			<button className="ql-underline"/>
			<button className="ql-list" value="ordered"/>
			<button className="ql-list" value="bullet"/>
			<button className="ql-clean"/>
			<button className="ql-link"/>
			<select className="ql-insertCustomTags">
				<option className='custom-dropdown-def-option' value=' '/>
				{
					Object.keys(props.templateTags).
						map((key, i) => <option key={i}
												value={props.templateTags[key]}>{key}</option>)
				}
			</select>
		</div>
	);
};

const mapStateToProps = state => ({
	activeTab: state.forms.messagesType.active_tab,
	menContent: state.forms.messagesType.text_men,
	womenContent: state.forms.messagesType.text_women,
	textFromManualSend: state.forms.manuallyMessage.text,
	textFromManualRestaurantSend: state.forms.manuallyMessageRestaurant.text,
});

export default connect(mapStateToProps)(ReactQuillWrapper);
